import { Input, FormGroup, Form} from "reactstrap";
import React from "react";
import {useLocalStorage} from "../Hooks/useLocalStorage";

function ChecklistItem(props) {
    const [text, setText] = useLocalStorage(props.name, "");
    const [checked, setChecked] = useLocalStorage(props.name + "checkbox", false);

    const handleCheck = () => {
        setChecked(!checked);
    };

    return (
        <Form>
            <FormGroup check={props.checkbox} inline style={{width:'100%'}}>
                {props.checkbox ?
                    <Input tabIndex={-1} type="checkbox" checked={checked} onChange={handleCheck}/>
                    :
                    <></>}

                <Input
                    style={{textDecoration: checked ? "line-through" : "none"}}
                    onChange={e => setText(e.target.value)}
                    value={text}/>
            </FormGroup>
        </Form>
    )
}

export default ChecklistItem;