import './App.css';
import {Row, Col, Container} from "reactstrap";
import React, {useEffect, useState} from "react";
import Header from "./Components/Header";
import Body from "./Components/Body";

function App() {
    const [width, setWindowWidth] = useState(0);

    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        return () =>
            window.removeEventListener('resize' ,updateDimensions);
    }, [])

    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
    }

    const oneColumn = width < 1000;
    const margins = width < 500;

    let columnSize = margins ? 'col-12' : 'col-9';

    return (
        <Container>
            <Row className={'mb-5'}>

                { !margins ? <Col/> : <></>}

                <Col className={columnSize}>
                    <Row>
                        <Header/>
                    </Row>
                    <Row>
                        <Body showOneColumn={oneColumn}/>
                    </Row>
                </Col>

                { !margins ? <Col/> : <></>}
            </Row>
        </Container>
    );
}

export default App;
