import ChecklistItem from "./ChecklistItem";
import React from "react";

function Checklist(props) {
    const rows = [];

    for (let i = 0; i < props.numRows; i++)
        rows.push
        (
            <ChecklistItem
                name={props.title + props.index + i.toString()}
                key={i}
                checkbox={props.checkbox}/>
        );

    return (
        <>
            { rows }
        </>
    )
}

export default Checklist;