import Checklist from "./Checklist";
import React from "react";
import {Row, Col} from "reactstrap";

function DoubleChecklist(props) {
    if (props.showOneColumn) {
        return (
            <Row>
                <Col>
                    <Checklist index={0} title={props.title} numRows={6} checkbox={props.checkbox}/>
                </Col>
            </Row>
        )
    } else {
        return (
            <Row>
                <Col>
                    <Checklist index={0} title={props.title} numRows={6} checkbox={props.checkbox}/>
                </Col>
                <Col>
                    <Checklist index={1} title={props.title} numRows={6} checkbox={props.checkbox}/>
                </Col>
            </Row>
        )
    }
}

export default DoubleChecklist;