import DoubleChecklistWithTitle from "./DoubleChecklistWithTitle";
import React from "react";

const headings = ['today', 'tomorrow', 'next week'];

function Body(props) {
    return (
        <>
            {
                headings.map(title =>
                    <DoubleChecklistWithTitle
                        title={title}
                        showOneColumn={props.showOneColumn}
                        key={title}
                        checkbox={true}/>
                )
            }

            <DoubleChecklistWithTitle
                title={'with my life'}
                showOneColumn={true}
                checkbox={false}/>
        </>
    )
}

export default Body;