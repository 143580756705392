import DoubleChecklist from "./DoubleChecklist";

function DoubleChecklistWithTitle(props) {
    return (
        <>
            <h3>{props.title}:</h3>
            <DoubleChecklist
                showOneColumn={props.showOneColumn}
                checkbox={props.checkbox}
                title={props.title}/>
        </>
    )
}

export default DoubleChecklistWithTitle;