import React from "react";
import {Button} from "reactstrap";

function Header(props) {
    return (
        <>
            <span>
                <h1 style={{ margin:0 }}
                    className={'display-2 d-inline text-danger'}>
                    <strong>
                        WTF
                    </strong>
                </h1>
                <h3 className={'d-inline'}>am I doing?</h3>
            </span>
            <span>
                <Button outline
                        style={{width: 'auto'}}
                        onClick={
                            () => {
                                window.localStorage.clear();
                                window.location.reload(false);
                            }}>
                    reset
                </Button>
            </span>
        </>
    )
}

export default Header;